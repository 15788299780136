/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: rgb(18, 18, 18);
    color: rgba(255, 255, 255, 0.87);
    font-size: 1rem;
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-weight: 400;
    text-rendering: optimizelegibility;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  h1 {
    background-color: #4158d0;
    background-image: linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #85ffbd 100%);
  
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  h3 {
    font-size: 1.4rem !important;
    background-color: #8e9ffa;
    background-image: linear-gradient(45deg, #8e9ffa 0%, #f874ef 10%, #ffcc70 29%, #ffffff 100%);
  
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .patterns {
    background-image: url('./images/bg-effects.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .load {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  input {
    height: 40px;
  }
  
  .gradient:focus {
    border: 1px solid transparent;
    background-image: linear-gradient(#141414, #141414), linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #85ffbd 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  
  input,
  textarea {
    background: #121212c5;
    border-radius: 0.3rem;
    border: 1px solid transparent;
  }
  
  *:focus:not(.focus-visible) {
    outline: none;
  }

  .Footer {
    background-color: rgb(18, 18, 18);;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    width: 90vw;
    padding: 0.5rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;
 }
  .Footer__copyright {
    letter-spacing: 2px;
 }
  .Footer__copyright span {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding-left: 1rem;
    margin-left: 0.5rem;
 }
  .Footer__message {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 2rem;
 }
  .Footer__bold {
    font-size: 15px;
    color: #ed6a5a;
    margin: auto 5px;
 }

 .form-submitted {
   margin-left: 40%;
   width: 200px;
   height: 200px;
 }

.highlight-container, .highlight {
  position: relative;
} 
 
.highlight-container
{
  display: inline-block;
}

.highlight-container::before {
  content: ' ';
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: rgba(255,140,0,0.5);
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
}
 


.experiences {
  width: 90%;
  max-width: 1200px;
  margin: 100px auto;
}
.experiences__experienceCont{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.circle-container {
  width: 150px;
  height: 150px;
}





.circle-sketch-highlight {
  position: relative;
}

.circle-sketch-highlight:before {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: -0.1em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(241, 79, 76);
  position: absolute;
  border-right-color: transparent;
  width: 100%;
  height: 1em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em;
}

.circle-sketch-highlight:after {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: 0.1em;
  padding: 0.1em 0.25em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(241, 79, 76);
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 100%;
  height: 1em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
}











  
 @media screen and (max-width:600px) {
   .form-submitted {
     margin-left: 25%;
   }
   .experiences {
    margin: 20px auto;
    }
 }